$color-white : #ffffff;
$color-black : #000000;
$color-orange : #ff0042;
$color-lighter-black : #dbdbdb;
$color-yellow : #f7ca18;
$color-blue : #1991d1;

$color-primary : #2a3245;
$color-secondary : $color-orange;

$color-warning : $color-yellow;
$color-info : $color-lighter-black;
$color-danger : $color-orange;
$color-success : $color-primary;

/*Features*/
$color-sidebar : $color-primary;
