.rv-media-thumbnail {
    position : relative;
    width : 100%;
    overflow: hidden;
    &:before {
        content: '';
        display : block;
        padding-bottom : 100%;
        height : 0;
    }
    i {
        font-size : 30px;
        @include center-all;
    }
    img {
        max-height: 100%;
        @include center-all;
    }
}
