#rv_media_body {
    display        : flex;
    flex-direction : column;
}

.rv-media-container {
    width          : 100%;
    min-height     : 100%;
    height         : 100%;
    font-size      : 13px;
    overflow       : auto;
    display        : flex;
    flex-grow      : 1;
    flex-direction : column;
    * {
        font-size : 13px;
    }
    .rv-media-wrapper {
        display   : flex;
        width     : 100%;
        height    : 100%;
        overflow  : hidden;
        position  : relative;
        min-width : 750px;
        flex-grow      : 1;
        .rv-media-aside,
        .rv-media-main-wrapper {
            @include lib-css(align-items, stretch);
        }
        .rv-media-main-wrapper {
            @include lib-css(flex-grow, 1);
            display : flex;
            @include lib-css(flex-direction, column);
            .rv-media-main {
                @include lib-css(flex-grow, 1);
                @include lib-css(flex-direction, row);
                display : flex;
                height: 200px;
            }
        }
        &.on-loading {

        }
    }

    .rv-media-items,
    .rv-media-details {
        @include lib-css(align-items, stretch);
        display  : flex;
        overflow : auto;
        position : relative;
    }

    .rv-media-items {
        @include lib-css(flex-grow, 1);
        @include lib-css(flex-direction, column);
        @include lib-css(user-select, none);
        .rv-media-item {
            overflow         : hidden;
            background-color : rgba($color-lighter-black, 0.3);
        }
    }

    .rv-media-details {
        width       : 250px;
        min-width   : 250px;
        border-left : 1px solid $color-lighter-black;
        @include lib-css(flex-direction, column);
        @include lib-css(transition, all 0.3s ease-in-out);
        overflow    : hidden;
        overflow-y  : auto;
        .rv-media-thumbnail,
        .rv-media-description {
            width     : 250px;
            min-width : 250px;
        }
        .rv-media-thumbnail {
            border-bottom : 1px solid $color-lighter-black;
            overflow      : visible;
            i {
                font-size : 70px;
                &.fa.fa-picture-o {
                    opacity: 0.5;
                }
            }
            img {
                max-width : 90%;
            }
        }
        .rv-media-description {
            padding  : 10px;
            position : relative;
            .rv-media-name {
                > p {
                    font-weight    : 700;
                    margin  : 0;
                }
                + .rv-media-name {
                    margin-top : 5px;
                }
                > span {
                    display       : block;
                    white-space   : nowrap;
                    overflow      : hidden;
                    text-overflow : ellipsis;
                }
            }
        }
    }

    #media_details_collapse:checked ~ .rv-media-main-wrapper .rv-media-details {
        width     : 0;
        min-width : 0;
        border    : 0 none;
    }

    #media_details_collapse:checked ~ .rv-media-main-wrapper .rv-media-tools .collapse-panel {
        i {
            @include lib-css(transform, rotate(-180deg));
        }
    }

    @media (max-width : $screen-md-max) {
        .rv-media-details {
            width     : 220px;
            min-width : 220px;
            .rv-media-thumbnail,
            .rv-media-description {
                width     : 220px;
                min-width : 220px;
            }
        }
    }

    @media (max-width : $screen-sm-max) {
        .rv-media-wrapper .rv-media-main-wrapper {
            width : 100%;
        }
        .rv-media-details {
            width     : 200px;
            min-width : 200px;
            .rv-media-thumbnail,
            .rv-media-description {
                width     : 200px;
                min-width : 200px;
            }
        }
    }
}
