.pace {
    @include lib-css(pointer-events, none);
    @include lib-css(user-select, none);

    .pace-progress {
        background : $color-orange;
        position   : fixed;
        z-index    : 2000;
        top        : 0;
        right      : 100%;
        width      : 100%;
        height     : 2px;
    }
    .pace-progress-inner {
        display           : block;
        position          : absolute;
        right             : 0;
        width             : 100px;
        height            : 100%;
        box-shadow        : 0 0 10px $color-orange, 0 0 5px $color-orange;
        opacity           : 1.0;
        @include lib-css(transform, rotate(3deg) translate(0px, -4px));
    }
    .pace-activity {
        display           : block;
        position          : fixed;
        z-index           : 2000;
        top               : 20px;
        right             : 15px;
        width             : 14px;
        height            : 14px;
        border            : solid 2px transparent;
        border-top-color  : $color-white;
        border-left-color : $color-white;
        border-radius     : 10px;
        @include lib-css(animation, pace-spinner 400ms linear infinite);
    }
}

.pace-inactive {
    display : none;
}

@-webkit-keyframes pace-spinner {
    0% {
        -webkit-transform : rotate(0deg);
        transform         : rotate(0deg);
    }
    100% {
        -webkit-transform : rotate(360deg);
        transform         : rotate(360deg);
    }
}

@-moz-keyframes pace-spinner {
    0% {
        -moz-transform : rotate(0deg);
        transform      : rotate(0deg);
    }
    100% {
        -moz-transform : rotate(360deg);
        transform      : rotate(360deg);
    }
}

@-o-keyframes pace-spinner {
    0% {
        -o-transform : rotate(0deg);
        transform    : rotate(0deg);
    }
    100% {
        -o-transform : rotate(360deg);
        transform    : rotate(360deg);
    }
}

@-ms-keyframes pace-spinner {
    0% {
        -ms-transform : rotate(0deg);
        transform     : rotate(0deg);
    }
    100% {
        -ms-transform : rotate(360deg);
        transform     : rotate(360deg);
    }
}

@keyframes pace-spinner {
    0% {
        transform : rotate(0deg);
    }
    100% {
        transform : rotate(360deg);
    }
}