.rv-media-header {
    .rv-media-top-header,
    .rv-media-bottom-header {
        display: flex;
        width : 100%;
        padding : 10px;
        @include lib-css(flex-direction, row);
        border-bottom: 1px solid $color-lighter-black;
    }
    .rv-media-top-header {
        background-color: #f5f5f5;
        .rv-media-actions,
        .rv-media-search {
            display : flex;
            @include lib-css(align-items, center);
        }
        .rv-media-actions {
            @include lib-css(flex-grow, 1);
            .btn {
                margin-right : 5px;
            }
        }
        .rv-media-search {
            .input-search-wrapper {
                position : relative;
            }
            input {
                box-shadow: none !important;
                border: 1px solid $color-primary;
                color: $color-primary;
                @include lib-css(transition, all 0.3s ease-in-out);
                width: 200px;
                &:focus {
                    border: 1px solid $color-orange;
                }
            }
            .btn {
                position : absolute;
                top : 0;
                right : 0;
                color: $color-primary;
                &:hover,
                &:focus,
                &:active {
                    color: $color-orange;
                }
            }
        }
    }
    .rv-media-bottom-header {
        .rv-media-breadcrumb,
        .rv-media-tools {
            display : flex;
            @include lib-css(align-items, center);
        }
        .rv-media-tools {
            .dropdown {
                display: inline-block;
            }
            .btn-group {
                margin-left : 5px;
            }
        }
    }
}
