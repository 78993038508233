@import "partials/variables";
@import "media";
//@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,400i,500,700&subset=latin-ext);

hr{
	border-color: #ff0042;
}
#page-wrapper{
	background: #f5f8fd;
	.panel{
		background:  #f5f8fd;
	}
}
.text-success{
	color: #5cea94 !important;
	a{
		color: $orange-color;
	}
}
.text-warning {
    color: #ffee36 !important;
}
.label-success, .alert-success{
	background-color:#5cea94;
}
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}
.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}
/*Just change your choise color here its theme Colors*/
body {
  background: #fff;
  min-height: 100%;
  height: auto !important;
}
/*Top Header Part*/
.boxShadow{
	-webkit-box-shadow: 0 2px 27px 7px rgba(3,169,245,0.13);
	-moz-box-shadow: 0 2px 27px 7px rgba(3,169,245,0.13);
	box-shadow: 0 2px 27px 7px rgba(3,169,245,0.13);
}
.navbar-header{
	background: $main-color;
}
.top-left-part {
  background: #fff;
}
.logo i {
  color: #ffffff;
}
.navbar-top-links > li > a {
  color: #ffffff;
  min-height: 50px;
  line-height: 50px;
}
/*Right panel*/
.right-sidebar .rpanel-title {
  background: $main-color;
}
/*Bread Crumb*/
.bg-title .breadcrumb .active {
  color: $main-color;
}
/*Sidebar*/
.sidebar {
  background: $main-color;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.4);
}
.sidebar .label-custom {
  background: #01c0c8;
}
#side-menu li a {
  color: #fff;
  border-left: 0px solid #fff;
}
#side-menu .nav-small-cap{
  border-bottom: none !important;
  border-left: none;
  font-weight: 400;
  color: $orange-color;
}
#side-menu li a.zamow{
  color: #d7dceb;
}
#side-menu > li > a:hover,
#side-menu > li > a:focus {
  background: $main-color;
  opacity: .8;
}
#side-menu > li > a.active {
  border-left: 3px solid $orange-color;
  color: #fff;
  font-weight: 500;
  background: $dark-color;
  opacity: .8;
}
#side-menu > li > a.active i {
  color: $orange-color;
}
#side-menu ul > li > a:hover {
  color: $orange-color;
  background: $dark-color;
}
#side-menu ul > li > a.active {
  color: #fff;
  font-weight: 500;
  background: $dark-color;
}
.sidebar #side-menu .user-pro .nav-second-level a:hover {
  color: $main-color;
}
.sidebar #side-menu .user-pro>a{
  background: $main-color;
  opacity: .8;
}
.sidebar #side-menu .user-pro>a:hover{
  opacity: .7;
}
.fix-sidebar .top-left-part {
  background: $main-color;
}
/*themecolor*/
.bg-theme {
  background-color: #fb9678 !important;
}
.bg-theme-dark {
  background-color: #01c0c8 !important;
}
/*Chat widget*/
.chat-list .odd .chat-text {
  background: $main-color;
}
/*Button*/
.btn-custom {
  background: $main-color;
  border: 1px solid $main-color;
  color: #ffffff;
}
.btn-custom:hover {
  background: $main-color;
  opacity: 0.8;
  color: #ffffff;
  border: 1px solid $main-color;
}
/*Custom tab*/
.customtab li.active a,
.customtab li.active a:hover,
.customtab li.active a:focus {
  border-bottom: 2px solid $main-color;
  color: $main-color;
}
.tabs-vertical li.active a,
.tabs-vertical li.active a:hover,
.tabs-vertical li.active a:focus {
  background: $main-color;
  border-right: 2px solid $main-color;
}
/*Nav-pills*/
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background: $main-color;
  color: #ffffff;
}
.features-area{
    padding: 50px 0px;
    img{
        box-shadow: 0px 30px 70px 0px rgba(26, 38, 74, 0.25);
        &:hover{
          opacity: .7;
        }
    }
}
.features-content{
    padding-top: 40px;
    h2{
        font: 600 35px/48px;
        color: #1a264a;
        padding-bottom: 28px;
        padding-right: 13px;
        text-transform: uppercase;
    }
    p{
        color: #585e68;
        padding-bottom: 44px;
    }
}
.features-content.f-pading{
  padding-top: 38px;
  max-width: 100%;
  float: left;
}
/* wyszukiwarka */
.graphical-area{
    padding-top: 40px;
    .section-title{
      max-width: 650px;
      margin: 0 auto 60px;
      text-align: center;
    }
    img{
         box-shadow: 0px 30px 70px 0px rgba(26, 38, 74, 0.25);
         &:hover{
          opacity: .7;
        }
    }
}
.call-action-area{
    text-align: center;
    padding: 120px 0px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: $main-color;
        // background: -webkit-linear-gradient(to right, #363795, #005C97);
        // background: linear-gradient(to right, #363795, #005C97);
        border: 0px;
        z-index: -1;
        opacity: 0.98;
    }
    &:after{
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: url('../admin/img/call-action-bg.jpg') no-repeat scroll;
        background-size: cover;
        background-position: 50% 50%;
        z-index: -1;
        opacity: 0.15;
        background-attachment: fixed;
    }
    .call-action-text{
        display: inline-block;
        font-size: 32px;
        line-height: 60px;
        color: #fff;
        vertical-align: middle;
        margin-right: 40px;
    }
    p{
        display: inline-block;
        font-size: 20px;
        line-height: 30px;
        color: #fff;
        vertical-align: middle;
        margin-right: 40px;
        width: 100%;
    }
}
.widgetA{
  -webkit-box-shadow: 0 8px 17px 0 rgba(0,0,0,.1), 0 6px 20px 0 rgba(0,0,0,.1);
  box-shadow: 0 8px 17px 0 rgba(0,0,0,.1), 0 6px 20px 0 rgba(0,0,0,.1);
  display: block;
}
.widgetA.zamow{
  opacity: .5;
  cursor: not-allowed;
}
.widgetA:hover{
  opacity: .7;
}
 .widgetA .two-part .counter i{
  font-size: 20px;
 }
.widgetA .two-part .text-info, .widgetA .two-part .counter{
  color: $orange-color !important;
}
.widgetA:hover .two-part .text-info,  .widgetA:hover .two-part .counter{
  color: $main-color !important;
}
.backgroundCloud{
	// background: rgb(24,27,58) !important; /* Old browsers */
	// background: -moz-linear-gradient(left, rgba(24,27,58,1) 0%, rgba(22,30,109,1) 100%) !important; /* FF3.6-15 */
	// background: -webkit-linear-gradient(left, rgba(24,27,58,1) 0%,rgba(22,30,109,1) 100%) !important; /* Chrome10-25,Safari5.1-6 */
	// background: linear-gradient(to right, rgba(24,27,58,1) 0%,rgba(22,30,109,1) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#181b3a', endColorstr='#161e6d',GradientType=1 ) !important; /* IE6-9 */
	background: #2a3245;
}
/*Tabele*/
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, 
.table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, 
.table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td{
	border: none;
}
.table-bordered > thead > tr > th, .table-bordered > tbody > tr, 
.table-bordered > tfoot > tr > th, .table-bordered > thead > tr, 
.table-bordered > tbody > tr > td, .table-bordered > tfoot > tr{
	border-bottom: 1px solid #d8e1ea;
}
.table-bordered > thead > tr > th{
	padding: 15px 8px;
}
.table-bordered > tbody > tr:nth-child(odd){
	background: #fff;
}
.table-bordered > tbody > tr:nth-child(even){
	background: #f5f8fd;
}
.table-bordered > tbody > tr:hover{
    box-shadow: 0 2px 17px 5px rgba(3, 169, 245, 0.13);
}
.app-search .form-control::-moz-placeholder {
  color: #d8e1ea !important;
}
.app-search .form-control::-webkit-input-placeholder {
  color: #d8e1ea !important;
}
.app-search .form-control::-ms-placeholder {
  color: #d8e1ea !important;
}
/*Użytkownicy*/
.white-box{
	&.userBoxInfo{
		position: relative;
		.imgUserInfo{
			text-align: center;
			padding: 5px 30%;
			img{
				margin: 20px auto;
				box-shadow: 0 2px 17px 5px rgba(3, 169, 245, 0.13);
			}
		}
		&:hover{
			box-shadow: 0 2px 17px 5px rgba(3, 169, 245, 0.13);
		}
	}
}
/* inputy*/
.form-horizontal{
	.control-label{
		font-weight: 700;
		font-size: 15px;
	}
}
.select2-container-multi.select2-container-disabled .select2-choices, .select2-container-multi .select2-choices{
	background: #f5f8fd;
	border-radius: 15px !important;
}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice, .select2-container-multi .select2-choices .select2-search-choice{
	background: $main-color;
	border: 1px solid $main-color;
	color: #fff;
	padding: 7px 20px;
	border-radius: 15px;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close, .select2-container-multi .select2-search-choice-close{
	color: $orange-color;
	left: 5px;
	top: 7px;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover, .select2-container-multi .select2-search-choice-close:hover{
	opacity: .7;
}
.icheck-list{
	&.pageTemplateChoice{
		float: none;
		width: 100%;
		li{
			text-align: center;
			.iradio_square-blue{
				display: block;
				margin: 20px auto;
			}
			img{
				max-width: 50%;
				margin: 0px auto;
			}
			label{
				display: block;
				text-align: center;
			}
			&:hover{
				box-shadow: 0 2px 17px 5px rgba(3, 169, 245, 0.13);
			}
			&.templateDisabled{
				opacity: .6;
				&:hover{
					cursor: not-allowed;
				}
			}
		}
	}
}
/*sidebar*/

.right-sidebar{
	background: $main-color;
	height: 40%;
	overflow: auto;
	&.shw-rside{
		right: 30px;
	}
	.rpanel-title{
		border-bottom: 2px solid $orange-color;
	}
	.r-panel-body{
		width: 100%;
		float: left;
		position: relative;
		padding-bottom: 60px;
		min-height: 90%;
		overflow: auto;
		ul{
			clear: both;
			float: left;
			width: 100%;
			li{
				float: left;
				width: 100%;
				a{
					color: #fff;
					padding: 10px 0;
					i{
						color: #5cea94;
					}
					img{
						border: 1px solid #5cea94;
					}
					span{
						color: $orange-color;
					}
					&:hover{
						color: $orange-color;
					}
				}
				&:last-child{
					position: absolute;
					width: 100%;
					height: auto;
					bottom: 0;
					left: 0;
					right: 0;
					background: $orange-color;
					text-align: center;
					a{
						color: #fff;
						text-align: center;
						&:hover{
							color: $main-color;
						}
					}
				}
			}
		}
	}
}
/*TABSY*/
.tabs-style-linemove {
	nav{
		li{
			opacity: .7;
			a{
				padding: 10px 0;
				img{
					margin: 0px auto 10px;
					max-width: 40px;
					display: block;
				}
			}
			&:hover, &.tab-current{
				box-shadow: 0 -3px 16px 2px rgba(3, 169, 245, 0.1);
				opacity: 1;
			}
		}
	}
	.content-wrap{
		box-shadow: 0 -3px 16px 2px rgba(3, 169, 245, 0.1);
	}
}
/*MENU*/
.NavItemsList{
	position: relative;
	.MenuBoxInfo{
		padding: 30px;
		text-align: center;
		border: 3px solid #fff;
		h3{
			font-size: 25px;
			text-transform: uppercase;
			color: $main-color;
			font-weight: 700;
			margin-bottom: 25px;
			&.littleSmallerTitle{
				font-size: 15px;
				margin-bottom: 10px;
			}
		}
		h5{
			text-transform: uppercase;
			font-size: 14px;
			color: $main-color;
			margin-bottom: 25px;
		}
		p{
			font-size: 14px;
			color: $main-color;
			margin-bottom: 25px;
		}
		&:hover{
			box-shadow: 0 2px 17px 5px rgba(3, 169, 245, 0.13);
			border: 3px solid $orange-color;
			h3{
				color: $orange-color;
			}
			h5{
				color: $orange-color;
			}
			p{
				color: $orange-color;
			}
		}
	}
}
.menuItem{
	&.dd-item{
		position: relative;
		margin-bottom: 10px;
		background: transparent;
		.dd-handle{
			background: transparent;
			padding: 8px 15px;
			color: $main-color;
			height: 40px;
			line-height: 24px;
			margin: 0 0 10px;
			border: 1px solid #eceeef;
		}
		.MenuActionGroup{
			width: 30px;
			position: absolute;
			height: 40px;
			text-align: right;
			color: $orange-color;
			right: 0;
			top: 0;
			.PlayActionOnMenuItem{
				background: none;
				border: none;
				height: 100%;
				&:hover{
					color: $main-color;
				}
			}
		}
		&.hiddenMenuItem{
			opacity: .3;
			&:hover{
				opacity: .7;
			}
		}
	}
}
.MenuOptionToggle{
	display: none;
	position: relative;
}
.deleteMenuItemBtn{
	color: $orange-color;
	position: absolute;
	left: 20px;
	bottom: 20px;
	width: 100px;
	height: 30px;
	opacity: .7;
	text-align: left;
	font-style: 15px;
	&:hover{
		opacity: 1;
		cursor: pointer;
	}
}
#nestable{
	max-width: 100%;
	.btn-info.disabled, .btn-info:disabled{
		border-color: $main-color;
		opacity: .6;
	}
}
/*TAbela ze stronami*/
.label-success, .alert-success {
    background-color: #5cea94;
    color: #fff;
}
.rowPageOptions{
	position: relative;
	background: transparent;
	padding: 10px 0;
	border: 1px solid #d8e1ea;
	margin-top: -1px;
	&:nth-child(even){
		background: #fafbfd;
	}
	.pageTitle{
		color: $main-color;
		font-weight: 600;
		padding: 15px;
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.pageNumber{
		display: inline-block;
		width: 30px;
		text-align: center;
		color: $main-color;
		font-weight: 300;
	}
	.pageDate{
		color: $main-color;
		padding-top: 15px;
		padding-bottom: 15px;
		span{
			display: inline-block;
			padding-right: 10px;
		}
	}
	.pageStatus{
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.pageLang{
		padding-top: 15px;
		padding-bottom: 15px;
		color: $orange-color;
		text-transform: uppercase;
		span{
			display: inline-block;
			padding-right: 10px;
			color: $main-color;
		}
	}
	.pageOptionsOnHover{
		display: none;
		transition: all 0.5s ease;
		width: 100%;
		background: rgba(255,255,255, .9);
		text-align: right;
		box-shadow: none;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 9999;
		padding: 15px;
	}
	&:hover{
		.pageOptionsOnHover{
			display: block;
			box-shadow: 0 4px 17px 5px rgba(3, 169, 245, 0.17);
		    transform: scale(1.001, 1.01);
		}
	}
}
.checkboxes-and-radios {
  margin: 10px auto 0;
  position: relative;
  padding: 0 0 30px;

  input {
    display: none;
  }

  label {
    cursor: pointer;
    position: relative;
    display: block;
    font-size: 14px;
    padding: 5px 0;
    float: left;
    width: 50%;
  }

  input[type="checkbox"], input[type="radio"] {
    position: absolute; 
    visibility: hidden !important;
  }

  input[type="checkbox"] + label, input[type="radio"] + label {

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -7.5px;
      box-sizing: border-box;
    }

    &:before {
      width: 40px;
      height: 20px;
      right: 50px;
      background: #fff;
      border: 1px solid #e4e3e1;
      border-radius: 15px;
    }

    &:after {
      width: 20px;
      height: 20px;
      right: 70px;
      background: $main-color;
      border-radius: 50%;
      transition: all 200ms ease-out;
      box-shadow: 0 1px 3px rgba(0,0,0,0.4);
    }
  }
  input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
    &:after {
      right: 50px;
      background: $orange-color;
    }
  }
  &.putItCenter{
  	label{
  		width: 70%;
  	}
  }
}
.editor-action-item{
	position: relative;
	margin: 10px 0 20px;
}
/*MOdale*/
.modal-dialog{
	max-width: 80%;
	margin: 10%;
}
.mediaPage{
	.rv-media-container{
		-webkit-box-shadow: 0 8px 17px 0 rgba(0,0,0,.1), 0 6px 20px 0 rgba(0,0,0,.1);
		box-shadow: 0 8px 17px 0 rgba(0,0,0,.1), 0 6px 20px 0 rgba(0,0,0,.1);
	}
}
.rv-media-container{
	.dropdown-menu{
		min-width: 180px;
		li{
			&.active{
				a{
					background-color: $orange-color;
				}
			}
		}
	}
	.context-menu-list{
		li{
			&:hover, &:focus, &:active, &:visited, &.context-menu-hover{
				background-color: $orange-color;
			}
		}
	}
}
.rv-media-aside{
	.rv-media-block-title{
		border-bottom: none !important;
		border-left: none;
		font-weight: 400;
		color: $orange-color;
	}
	.rv-media-aside-list{
		i{
			font-size: 14px;
			color: $orange-color;
		}
	}
}
.rv-upload-progress{
	.panel{
		.panel-heading{
			.panel-title{
				color: #fff;
			}
			.close-pane{
				i{
					margin-left: 0;
					font-size: 15px;
				}
			}
		}
		.rv-upload-progress-table{
			.rv-table-col{
				&:last-child{
					text-align: right;
				}
			}
		} 
	} 
}
/*Galeria*/
#ImagesSection{
	.popup-gallery{
		display: flex;
		.PhotoGalleryItemWrap{
			position: relative;
			margin: 0 25px 30px 0;
			.deleteItemID{
				position: absolute;
				right: -13px;
				top: -13px;
				z-index: 2;
				width: 26px;
				height: 26px;
				text-align: center;
				background-color: #fff;
				border: 1px solid $orange-color;
				border-radius: 50%;
				padding: 3px;
				i{
					color: $orange-color;
					font-size: 16px;
					line-height: 20px;
				}
				&:hover{
					background-color: $orange-color;
					border: 1px solid #fff;
					cursor: pointer;
					i{
						color: #fff;
					}

				}
			}
		}
	}
}