.media-modal-body {
    padding: 0;
    height : 600px;
    overflow-y: scroll;
    border: none;
}

.rv-media-breadcrumb {
    .breadcrumb {
        li:first-child {
            &:before {
                display : none !important;
            }
        }
    }
}

.rv-media-container {
    .rv-media-wrapper .rv-media-main-wrapper {
        background : #ffffff;
    }
    height: 100vh;
    #modal_add_folder {
        .modal-body {
            padding : 15px;
        }
    }
}


@media (max-width: 991px) {
    .rv-media-container {
        .rv-media-aside {
            z-index: 10000;
            .collapse-sidebar {
                line-height : 41px;
            }
        }
    }
}

#rv_media_modal {
    .modal-dialog {
        background : #ffffff;
    }
}

.rv-media-integrate-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}