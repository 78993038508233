$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;
$color-black: #000000;

// scaling... any units
$width: 50px;

.loading-wrapper {
    display : none;
}

.bb-loading {
    position : relative;
    background-color: rgba($color-black, 0.05);
    .loading-wrapper {
        position : absolute;
        top      : 50%;
        left     : 50%;
        transform: translate(-50%, -50%);
        display : block;

        .loader {
            position: relative;
            margin: 0 auto;
            width: $width;
            &:before {
                content: '';
                display: block;
                padding-top: 100%;
            }
        }

        .circular {
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .path {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round;
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

    @keyframes color {
        100%,
        0% {
            stroke: $red;
        }
        40% {
            stroke: $blue;
        }
        66% {
            stroke: $green;
        }
        80%,
        90% {
            stroke: $yellow;
        }
    }

    @-webkit-keyframes color {
        100%,
        0% {
            stroke: $red;
        }
        40% {
            stroke: $blue;
        }
        66% {
            stroke: $green;
        }
        80%,
        90% {
            stroke: $yellow;
        }
    }
}

// scaling... any units
$width: 50px;

.loading-icon {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 99;
    /* -webkit-animation: fa-spin 2s infinite linear; */
    /* animation: fa-spin 2s infinite linear; */
}
.loading-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    width: 50px;
    z-index: 22222;
    font-size: 40px;
    color: #fff;
}
.rv-media-container {
    .on-loading {
        position : relative;
        background-color: rgba($color-black, 0.05);
    }

    .loading-wrapper {
        @include center-all();
        display : block;
        background: rgba(255,255,255,0.4);
        width: 100%;
        height: 100%;
        .on-loading > & {
            display : block;
        }
        .showbox {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            top: 40%;
        }
        // end demo-specific

        .loader {
            position: relative;
            margin: 0 auto;
            width: $width;
            &:before {
                content: '';
                display: block;
                padding-top: 100%;
            }
        }

        .circular {
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .path {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round;
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

    @keyframes color {
        100%,
        0% {
            stroke: $red;
        }
        40% {
            stroke: $blue;
        }
        66% {
            stroke: $green;
        }
        80%,
        90% {
            stroke: $yellow;
        }
    }

    @-webkit-keyframes color {
        100%,
        0% {
            stroke: $red;
        }
        40% {
            stroke: $blue;
        }
        66% {
            stroke: $green;
        }
        80%,
        90% {
            stroke: $yellow;
        }
    }
}

.media-modal-loading {
    @include opacity(0);
    * {
        display : none !important;
    }
}
